<template>
    <div class="ml-10 mr-10">

        <TitleBar :loading="loading" :text="this.$t('company.settings.codes')"></TitleBar>

        <v-card :disabled="loading" :elevation="0" :loading="loading">
            <v-btn
                class="mb-2 mt-2"
                color="secondary"
                @click="newRoot"
            >
                <v-icon
                    left
                >
                    mdi-plus
                </v-icon>
                {{ $t("company.codes.new") }}
            </v-btn>
            <v-btn
                class="mb-2 mt-2 ml-2"
                color="secondary"
                @click="fileImport = true"
            >
                <v-icon
                    left
                >
                    mdi-file-import
                </v-icon>
                {{ $t("company.codes.import") }}
            </v-btn>

            <csv-importer v-if="fileImport" :headers="['code', 'description']" class="mb-5 mt-5"
                          v-on:cancel="fileImport = false" v-on:data="importCodes"></csv-importer>

            <p v-if="importing.loading">{{ $t("csv.importing", {doing: importing.done, total: importing.total}) }}</p>

            <v-treeview
                v-if="items.length > 0"
                :active.sync="active"
                :items="items"
                class="mt-5"
                item-key="uuid"
                transition
            >
                <template v-slot:prepend>
                    <v-icon>
                        mdi-bank
                    </v-icon>
                </template>
                <template v-slot:label="{item}">
                    {{ item.code }} - {{ item.description }}
                </template>
                <template v-slot:append="{item}">

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                small
                                v-bind="attrs"
                                @click="editElement(item.code, item.description, item.parent, item.uuid)"
                                v-on="on"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t("company.codes.edit") }}</span>
                    </v-tooltip>


                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="ml-2"
                                icon
                                small
                                v-bind="attrs"
                                @click="newChildren(item.uuid)"
                                v-on="on"
                            >
                                <v-icon>
                                    mdi-folder-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t("company.codes.new_child") }}</span>
                    </v-tooltip>

                    <v-tooltip v-if="!item.children" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="ml-2"
                                color="error"
                                icon
                                small
                                v-bind="attrs"
                                @click="deleteElement(item.uuid)"
                                v-on="on"
                            >
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t("company.codes.delete") }}</span>
                    </v-tooltip>
                </template>
            </v-treeview>

            <v-card v-else class="mt-5" outlined>
                <v-card-title>{{ $t("company.codes.empty") }}</v-card-title>
            </v-card>

        </v-card>


        <v-dialog
            v-model="dialog"
            width="500"
        >
            <v-form @submit.prevent="formSubmit">
                <v-card :disabled="loading" :loading="loading">
                    <v-card-title class="text-h5 grey lighten-2 mb-5">
                        <span v-if="current !== null">{{ $t("company.codes.update") }}</span>
                        <span v-else-if="parent === null">{{ $t("company.codes.new_root") }}</span>
                        <span v-else>{{ $t("company.codes.new_child") }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-text-field
                            v-model="code"
                            :disabled="loading"
                            :error-messages="codeErrors"
                            :label="$t('company.codes.code')"
                            outlined
                            @blur="$v.code.$touch()"
                            @input="$v.code.$touch()"
                        ></v-text-field>
                        <v-text-field
                            v-model="description"
                            :disabled="loading"
                            :error-messages="descriptionErrors"
                            :label="$t('company.codes.description')"
                            outlined
                            @blur="$v.description.$touch()"
                            @input="$v.description.$touch()"
                        ></v-text-field>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="dialog = false"
                        >
                            {{ $t("close") }}
                        </v-btn>
                        <v-btn
                            color="secondary"
                            text
                            type="submit"
                        >
                            {{ $t("company.codes.save") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import Accounting from "@/helpers/Accounting";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Utils from "@/helpers/Utils";
import CsvImporter from "@/components/CsvImporter.vue";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "AccountingCodeSettings",
    components: {TitleBar, CsvImporter},
    data() {
        return {
            active: [],
            items: [],
            loading: false,
            dialog: false,

            code: null,
            description: null,
            parent: null,
            current: null,
            fileImport: false,
            importing: {
                loading: false,
                done: 0,
                total: 0
            }
        };
    },
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.settings"),
                to: "/company/settings",
                exact: true
            },
            {
                text: this.$t("company.settings.codes"),
                to: "/company/settings/accounting-codes&",
                exact: true
            }
        ]);

        this.fetchElements();
    },
    methods: {
        // _getParent(code, importedCodes) {
        //     for (const importedCode of importedCodes.reverse()) {
        //         if (code.startsWith(importedCode.code)) {
        //             return importedCode.uuid;
        //         }
        //     }
        //     return null;
        // },
        _findParents(data) {
            data = data.filter(el => el.code.length > 0);
            data.sort((a, b) => {
                return a.code.localeCompare(b.code);
            });

            for (const dataKey in data) {
                data[dataKey].parentCode = null;
                for (const datum of data) {
                    if (datum.code.length < data[dataKey].code.length && data[dataKey].code.startsWith(datum.code)) {
                        data[dataKey].parentCode = datum.code;
                    }
                }
            }

            return data;
        },
        async importCodes(data) {
            this.loading = true;

            data.sort((a, b) => {
                return a.code.localeCompare(b.code);
            });

            let tree = this._findParents(data);

            console.log(tree);

            this.importing.loading = true;
            this.importing.total = data.length;
            let importedCodes = {};

            for (const treeElement of tree) {
                this.importing.done += 1;
                let resp = await Accounting.post("/accounting/codes", {
                    code: treeElement.code,
                    description: treeElement.description,
                    parent: importedCodes[treeElement.parentCode] !== undefined ? importedCodes[treeElement.parentCode] : null
                }).catch(() => {
                });
                if (resp !== undefined) {
                    importedCodes[treeElement.code] = resp.data.uuid;
                }
            }

            this.importing.loading = false;
            this.importing.done = 0;
            this.fileImport = false;
            this.loading = false;
            this.fetchElements();
        },
        newRoot() {
            this.code = this.description = this.parent = this.current = null;
            this.dialog = true;
        },
        newChildren(parent) {
            this.parent = parent;
            this.code = this.description = this.current = null;
            this.dialog = true;
        },
        editElement(code, description, parent, uuid) {
            this.current = uuid;
            this.code = code;
            this.description = description;
            this.parent = parent;
            this.dialog = true;
        },
        fetchElements() {
            this.loading = true;
            Accounting.get("/accounting/codes")
                .then(res => {
                    this.items = this.addParentId(res.data.codes, null);
                })
                .catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        formSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.loading = true;

            let url = "/accounting/codes";
            if (this.current !== null) {
                url += "/" + this.current;
            }

            Accounting.post(url, {
                    code: this.code,
                    description: this.description,
                    parent: this.parent
                })
                .then(() => {
                    this.fetchElements();
                    this.$store.commit("SET_ALERTS", [{
                        type: "success",
                        text: this.$t("company.codes.updated")
                    }]);
                    this.loading = false;
                    this.dialog = false;
                }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        },
        addParentId(arr, parent) {
            for (const arrKey in arr) {
                arr[arrKey]["parent"] = parent;
                if (arr[arrKey]["children"] !== undefined) {
                    arr[arrKey]["children"] = this.addParentId(arr[arrKey]["children"], arr[arrKey]["uuid"]);
                }
            }
            return arr;
        },
        deleteElement(uuid) {
            this.$swal({
                title: this.$t("company.codes.delete_title"),
                text: this.$t("company.codes.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("company.codes.delete_yes"),
                cancelButtonText: this.$t("company.codes.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/accounting/codes/" + uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("company.codes.deleted")
                            }]);
                            this.loading = false;
                            this.fetchElements();
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        }
    },
    mixins: [validationMixin],
    validations: {
        code: {required},
        description: {required}
    },
    computed: {
        codeErrors() {
            const errors = [];
            if (!this.$v.code.$dirty) return errors;
            !this.$v.code.required && errors.push(this.$t("company.codes.code_required"));
            return errors;
        },
        descriptionErrors() {
            const errors = [];
            if (!this.$v.description.$dirty) return errors;
            !this.$v.description.required && errors.push(this.$t("company.codes.description_required"));
            return errors;
        }
    }
};
</script>

<style scoped>

</style>